import { Component } from "react";

import Navbar from "./navbar.jsx";
import Sidebar from "./sidebar.jsx";
import LogsPage from "./logsPage.jsx";
import AlgoPage from "./algoPage.jsx";
import StartPage from "./startPage.jsx";
import SettingsPage from "./settingsPage.jsx";
import OrderbookPage from "./orderbookPage.jsx";
import NotificationsPage from "./notifications.jsx";

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.windowUpdateInterval = null;

    this.state = {
      currentPage: "algo",
      isSidebarOpen: true,
      drawerType: "persistent",
      notificationsUpdateIntervalID: null
    }
  }

  componentDidMount() {
    this.updateDrawerType();
    this.windowUpdateInterval = setInterval(this.updateDrawerType, 1000);

    this.setState({
      notificationsUpdateIntervalID: setInterval(this.props.fetchNotifications, 2000)
    });
  }

  componentWillUnmount() {
    clearInterval(this.windowUpdateInterval);
    clearInterval(this.state.notificationsUpdateIntervalID);

    this.windowUpdateInterval = null;
  }

  updateDrawerType = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 768 && this.state.drawerType === "persistent") {
      this.setState({
        drawerType: "temporary"
      });
    } else if (screenWidth >= 768 && this.state.drawerType === "temporary") {
      this.setState({
        drawerType: "persistent"
      });
    }
  }

  changeCurrentPage = page => {
    this.setState({
      currentPage: page
    });
  }

  toggleSidebar = e => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.isSidebarOpen = !prevState.isSidebarOpen;
      return newState;
    });
  }

  formatAlgoID = () => {
    if (this.props.algoID === "BNF_NE") {
      return "Banknifty Non-Expiry"
    } else if (this.props.algoID === "N50_MCS_E") {
      return "Nifty Mid-Cap Select Expiry"
    } else if (this.props.algoID === "FNF_E") {
      return "Finnifty Expiry"
    } else if (this.props.algoID === "BNF_E") {
      return "Banknifty Expiry"
    } else if (this.props.algoID === "N50_E") {
      return "Nifty 50 Expiry"
    } else if (this.props.algoID === "S_E") {
      return "Sensex Expiry"
    } else {
      return "###"
    }
  }

  getAlgoType = () => {
    // if (["BNF_NE", "N50_NE", "FNF_NE"].includes(this.props.algoID)) {
    if (["BNF_NE"].includes(this.props.algoID)) {
      return "NE"
    } else if (["BNF_E", "N50_MCS_E", "N50_E", "S_E", "FNF_E"].includes(this.props.algoID)) {
      return "E"
    } else {
      return ""
    }
  }

  render() {
    let page;

    if (this.state.currentPage === "algo") {
      if (this.props.algoStatus.algoStarted) {
        page = <AlgoPage
          algoID={this.props.algoID}
          stopAlgo={this.props.stopAlgo}
          getAlgoType={this.getAlgoType}
          formatAlgoID={this.formatAlgoID}
          algoStatus={this.props.algoStatus}
          squareoffAlgo={this.props.squareoffAlgo}
          fetchAlgoStatus={this.props.fetchAlgoStatus}
        />
      } else {
        page = <StartPage
          algoID={this.props.algoID}
          getAlgoType={this.getAlgoType}
          startAlgo={this.props.startAlgo}
          formatAlgoID={this.formatAlgoID}
          fetchAlgoStatus={this.props.fetchAlgoStatus}
        />
      }

    } else if (this.state.currentPage === "orderbook") {
      page = <OrderbookPage
        algoID={this.props.algoID}
        orders={this.props.orders}
        fetchOrders={this.props.fetchOrders}
        clearOrders={this.props.clearOrders}
        consolidatedOrders={this.props.consolidatedOrders}
        fetchConsolidatedOrders={this.props.fetchConsolidatedOrders}
        clearConsolidatedOrders={this.props.clearConsolidatedOrders}
      />

    } else if (this.state.currentPage === "logs") {
      page = <LogsPage
        logs={this.props.logs}
        algoID={this.props.algoID}
        fetchLogs={this.props.fetchLogs}
        clearLogs={this.props.clearLogs}
      />

    } else if (this.state.currentPage === "settings") {
      page = <SettingsPage
        algoID={this.props.algoID}
        settings={this.props.settings}
        fetchSettings={this.props.fetchSettings}
        startStrategy={this.props.startStrategy}
        stopStrategy={this.props.stopStrategy}
      />
    } else if (this.state.currentPage === "notifications") {
      page = <NotificationsPage
        algoID={this.props.algoID}
        notifications={this.props.notifications}
        fetchNotifications={this.props.fetchNotifications}
        readAllNotifications={this.props.readAllNotifications}
      />
    }

    return (
      <div>
        <Sidebar
          algoID={this.props.algoID}
          onClose={this.toggleSidebar}
          open={this.state.isSidebarOpen}
          setAlgoID={this.props.setAlgoID}
          drawerType={this.state.drawerType}
          toggleSidebar={this.toggleSidebar}
          currentPage={this.state.currentPage}
          changeCurrentPage={this.changeCurrentPage}
          unreadNotificationsCount={this.props.unreadNotificationsCount}
        />

        <div className={(this.state.isSidebarOpen && this.state.drawerType === "persistent") ? "sidebar-margin": ""}>
          <Navbar
            username={this.props.username}
            toggleSidebar={this.toggleSidebar}
            handleLogout={this.props.handleLogout}
            unreadNotificationsCount={this.props.unreadNotificationsCount}
          />
          {page}
        </div>
      </div>
    );
  }
}

export default MainPage;
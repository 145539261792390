import { Component } from "react";

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider
} from "@mui/material";

class SettingsPage extends Component {
  componentDidMount = async () => {
    await this.props.fetchSettings();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.algoID !== this.props.algoID) {
      await this.props.fetchSettings();
    }
  }

  handleSettingsSave = async e => {
    e.preventDefault();

    await this.props.handleSettingsSave();
    await this.props.getSettings();
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={6} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Algo Settings</Typography>
              <Divider sx={{ marginY: 2 }} />
              <Typography color={this.props.settings.strategyStatus === "running" ? "primary" : "error"}>
                {
                  this.props.settings.strategyStatus === "running" ? "Running" : "Stopped"
                }
              </Typography>
              {
                this.props.settings.strategyStatus === "running" &&
                <Button disableElevation sx={{ marginTop: 2 }} variant="contained" color="error" onClick={this.props.stopStrategy}>Stop</Button>
              }
              {
                this.props.settings.strategyStatus === "stopped" &&
                <Button disableElevation sx={{ marginTop: 2 }} variant="contained" color="success" onClick={this.props.startStrategy}>Start</Button>
              }
            </CardContent>
					</Card>
  			</Grid>
  		</Grid>
    );
  }
}

export default SettingsPage;

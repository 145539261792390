import { Component, Fragment } from "react";

import {
  Grid,
  Card,
  Select,
  Button,
  Dialog,
  Divider,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  CardContent,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@mui/material";

// const moment = require("moment");

class StartPage extends Component {
  constructor(props) {
    super(props);

    this.now = new Date();

    this.state = {
      lots: 1,
      maxLoss: -10000,

      capitalSize: 100000,
      maxRiskPct: 0,
      marginReqd: 0,

      startTime: "09:15",
      hedgeDistance: 0,
      expiry: "1970-01-01",
      expiryType: "Weekly",
      tradingMode: "Paper",

      entryPct1: 0,
      entryPct2: 0,
      entryPct3: 0,
      entryPct4: 0,

      slPct: 0,
      trailSLPct: 0,

      slPct1: 0,
      slPct2: 0,
      slPct3: 0,

      showConfirmationModal: false
    }
  }

  runAtStartUpdate = async () => {
    let localStorageState = localStorage.getItem(`iron-fly-algo-${this.props.algoID}-inputs`);

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem(`iron-fly-algo-${this.props.algoID}-inputs`, stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }

    await this.props.fetchAlgoStatus();
  }

  componentDidMount = async () => {
    this.runAtStartUpdate();
  }

  componentDidUpdate = async (prevProps) => {
    console.log(this.state);

    if (prevProps.algoID !== this.props.algoID) {
      this.runAtStartUpdate();
    }
  }

  handleInputChange = async e => {
    console.log(e);

    let name = e.target.name;
    let value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem(`iron-fly-algo-${this.props.algoID}-inputs`, stateToSave);
    });
  }

  handleStartAlgo = e => {
    e.preventDefault();

    this.closeConfirmationModal();
    this.props.startAlgo(this.state);
  }

  closeConfirmationModal = e => {
    this.setState({
      showConfirmationModal: false
    });
  }

  askConfirmation = e => {
    e.preventDefault();

    this.setState({
      showConfirmationModal: true
    });
  }

  render() {
    const algoType = this.props.getAlgoType();

    return (
      <div>
    		<Grid container spacing={2} justifyContent="center" alignItems="center">
    			<Grid item md={8} xs={12}>
            <Card sx={{ textAlign: "center", my: 5 }} variant="outlined">
              <CardContent>
                <Typography sx={{ textDecoration: "underline" }} variant="h5">{this.props.formatAlgoID()} Inputs</Typography>
                <Divider sx={{ marginY: 2 }} />
                <form id="start-algo-form">
                  {algoType === "NE" ?
                    <Fragment>
                      <TextField
                        required
                        fullWidth
                        name="lots"
                        label="Lots"
                        type="number"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.lots}
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="maxLoss"
                        label="Max Loss"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.maxLoss}
                        onChange={this.handleInputChange}
                      />
                    </Fragment> : null
                  }

                  {algoType === "E" ?
                    <Fragment>
                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="capitalSize"
                        variant="standard"
                        label="Capital Size"
                        sx={{ marginTop: 1 }}
                        value={this.state.capitalSize}
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="maxRiskPct"
                        label="Max Risk %"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.maxRiskPct}
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="marginReqd"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        label="Margin Required"
                        value={this.state.marginReqd}
                        onChange={this.handleInputChange}
                      />
                    </Fragment> : null
                  }

                  <TextField
                    required
                    fullWidth
                    type="time"
                    name="startTime"
                    label="Start Time"
                    variant="standard"
                    sx={{ marginTop: 1 }}
                    value={this.state.startTime}
                    onChange={this.handleInputChange}
                  />

                  <TextField
                    required
                    fullWidth
                    type="number"
                    variant="standard"
                    name="hedgeDistance"
                    sx={{ marginTop: 1 }}
                    label="Hedge Distance"
                    value={this.state.hedgeDistance}
                    onChange={this.handleInputChange}
                  />

                  <TextField
                    required
                    fullWidth
                    type="date"
                    name="expiry"
                    label="Expiry"
                    variant="standard"
                    sx={{ marginTop: 1 }}
                    value={this.state.expiry}
                    onChange={this.handleInputChange}
                  />

                  <FormControl
                    required
                    fullWidth
                    variant="standard"
                    sx={{ marginTop: 1, textAlign: "left" }}
                  >
                    <InputLabel id="expiry-type-label">Expiry Type</InputLabel>
                    <Select
                      name="expiryType"
                      label="Expiry Type"
                      labelId="expiry-type-label"
                      value={this.state.expiryType}
                      onChange={this.handleInputChange}
                    >
                      <MenuItem value="Weekly">Weekly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    required
                    fullWidth
                    variant="standard"
                    sx={{ marginTop: 1, textAlign: "left" }}
                  >
                    <InputLabel id="trading-mode-label">Trading Mode</InputLabel>
                    <Select
                      name="tradingMode"
                      label="Trading Mode"
                      labelId="trading-mode-label"
                      value={this.state.tradingMode}
                      onChange={this.handleInputChange}
                    >
                      <MenuItem value="Paper">Paper</MenuItem>
                      <MenuItem value="Live">Live</MenuItem>
                    </Select>
                  </FormControl>

                  {algoType === "NE" ?
                    <Fragment>
                      <Typography
                        variant="h6"
                        sx={{ marginTop: 2 }}
                      >
                        Entry Percentages
                      </Typography>

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="entryPct1"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.entryPct1}
                        onChange={this.handleInputChange}
                        label="Entry % 1 (for legs 6, 9, 12, 15)"
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="entryPct2"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.entryPct2}
                        onChange={this.handleInputChange}
                        label="Entry % 2 (for legs 7, 10, 13, 16)"
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="entryPct3"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.entryPct3}
                        onChange={this.handleInputChange}
                        label="Entry % 3 (for legs 17, 18, 19, 20)"
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="entryPct4"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.entryPct4}
                        onChange={this.handleInputChange}
                        label="Entry % 4 (for legs 27, 28)"
                      />

                      <Typography
                        variant="h6"
                        sx={{ marginTop: 2 }}
                      >
                        SL Percentages
                      </Typography>

                      <TextField
                        required
                        fullWidth
                        name="slPct"
                        label="SL %"
                        type="number"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.slPct}
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="trailSLPct"
                        label="Trail SL %"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.trailSLPct}
                        onChange={this.handleInputChange}
                      />
                    </Fragment> : null
                  }
                  {algoType === "E" ?
                    <Fragment>
                      <Typography
                        variant="h6"
                        sx={{ marginTop: 2 }}
                      >
                        Entry Percentages
                      </Typography>

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="entryPct1"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.entryPct1}
                        onChange={this.handleInputChange}
                        label="Entry % 1 (for legs 2, 4) (1st Entry Condition)"
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="entryPct2"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        value={this.state.entryPct2}
                        onChange={this.handleInputChange}
                        label="Entry % 2 (for legs 2, 4) (2nd Entry Condition)"
                      />

                      <Typography
                        variant="h6"
                        sx={{ marginTop: 2 }}
                      >
                        SL Percentages
                      </Typography>

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="slPct1"
                        variant="standard"
                        label="SL % 1 (2, 4)"
                        sx={{ marginTop: 1 }}
                        value={this.state.slPct1}
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="slPct2"
                        variant="standard"
                        label="SL % 2 (6, 8)"
                        sx={{ marginTop: 1 }}
                        value={this.state.slPct2}
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="slPct3"
                        variant="standard"
                        sx={{ marginTop: 1 }}
                        label="SL % 3 (10, 12)"
                        value={this.state.slPct3}
                        onChange={this.handleInputChange}
                      />
                    </Fragment> : null
                  }
                </form>

                {
                  algoType !== "" ?
                  <div sx={{ marginTop: 2 }}>
                    <Button
                      type="submit"
                      color="success"
                      disableElevation
                      variant="contained"
                      sx={{ marginTop: 2 }}
                      form="start-algo-form"
                      onClick={this.askConfirmation}
                    >
                      Start Algo
                    </Button>
                  </div> : null
                }
              </CardContent>
  					</Card>
    			</Grid>
    		</Grid>

        <Dialog onClose={this.closeConfirmationModal} open={this.state.showConfirmationModal}>
          <DialogTitle>Are you Sure ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to start the algo ?
            </DialogContentText>

            <Button
              disableElevation
              color="secondary"
              variant="contained"
              sx={{ marginTop: 2, marginRight: 1 }}
              onClick={this.closeConfirmationModal}
            >
              Go back
            </Button>

            <Button
              color="success"
              disableElevation
              variant="contained"
              sx={{ marginTop: 2 }}
              onClick={this.handleStartAlgo}
            >
              Start algo
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default StartPage;
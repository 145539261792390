import { Component } from "react";

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider
} from "@mui/material";

class OrderbookPage extends Component {
  constructor(props) {
    super(props);

    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";
  }

  componentDidMount = async () => {
    await this.props.fetchConsolidatedOrders();
    await this.props.fetchOrders();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.algoID !== this.props.algoID) {
      await this.props.fetchConsolidatedOrders();
      await this.props.fetchOrders();
    }
  }

  render() {
    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={10} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Consolidated Orderbook</Typography>
              <Divider sx={{ marginY: 2 }} />
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Timestamp</TableCell>
                      <TableCell align="center">Instrument</TableCell>
                      <TableCell align="center">Side</TableCell>
                      <TableCell align="center">Qty</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">No. of orders</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.props.consolidatedOrders.map((order, i) => (
                        <TableRow
                          key={i}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center" component="th" scope="row">{order.timestamp}</TableCell>
                          <TableCell align="center">{order.instrument}</TableCell>
                          <TableCell align="center">{order.side}</TableCell>
                          <TableCell align="center">{order.qty}</TableCell>
                          <TableCell align="center">{order.price}</TableCell>
                          <TableCell align="center">{order.numOrders}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                disableElevation
                color="primary"
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={this.props.fetchConsolidatedOrders}
              >
                Refresh
              </Button>
              <Button
                download
                color="success"
                target="_blank"
                rel="noreferrer"
                disableElevation
                variant="contained"
                sx={{ marginTop: 2, marginX: 1 }}
                href={`${this.URL}/api/orders/consolidated/export/?access-token=${localStorage.getItem('access-token')}`}
              >
                Export Consolidated Orders
              </Button>
              <Button
                color="error"
                disableElevation
                variant="contained"
                sx={{ marginTop: 2, marginLeft: 1 }}
                onClick={this.props.clearConsolidatedOrders}
              >
                Clear
              </Button>
            </CardContent>
					</Card>

					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Orderbook</Typography>
              <Divider sx={{ marginY: 2 }} />
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Timestamp</TableCell>
                      <TableCell align="center">Instrument</TableCell>
                      <TableCell align="center">Side</TableCell>
                      <TableCell align="center">Qty</TableCell>
                      <TableCell align="center">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.props.orders.map((order, i) => (
                        <TableRow
                          key={i}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center" component="th" scope="row">{order.timestamp}</TableCell>
                          <TableCell align="center">{order.instrument}</TableCell>
                          <TableCell align="center">{order.side}</TableCell>
                          <TableCell align="center">{order.qty}</TableCell>
                          <TableCell align="center">{order.price}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                disableElevation
                color="primary"
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={this.props.fetchOrders}
              >
                Refresh
              </Button>
              <Button
                download
                color="success"
                target="_blank"
                rel="noreferrer"
                variant="contained"
                disableElevation
                sx={{ marginTop: 2, marginX: 1 }}
                href={`${this.URL}/api/orders/export/?access-token=${localStorage.getItem('access-token')}`}
              >
                Export Orders
              </Button>
              <Button
                color="error"
                disableElevation
                variant="contained"
                onClick={this.props.clearOrders}
                sx={{ marginTop: 2 }}
              >
                Clear
              </Button>
            </CardContent>
					</Card>
  			</Grid>
  		</Grid>
    );
  }
}

export default OrderbookPage;

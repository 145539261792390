import { Component } from "react";

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider
} from "@mui/material";

class LogsPage extends Component {
  componentDidMount = async () => {
    await this.props.fetchLogs();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.algoID !== this.props.algoID) {
      await this.props.fetchLogs();
    }
  }

  render() {
    // const logs = [];

    // this.props.logs.map((log, i) => {
    //   let status = "";

    //   if (log.includes("SUCCESS")) {
    //     status = "SUCCESS"
    //   } else if (log.includes("INFO")) {
    //     status = "INFO"
    //   } else if (log.includes("ERROR")) {
    //     status = "ERROR"
    //   }

    //   logs.push({
    //     status: status,
    //     text: log
    //   });
    // });

    return (
  		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
  			<Grid item md={10} xs={12}>
					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined" className="logs-container">
            <CardContent>
              <Typography variant="h5">Logs</Typography>
              <Divider className="logs-container-divider" sx={{ marginY: 2 }} />

              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={this.props.fetchLogs}
                sx={{ marginRight: 1 }}
              >
                Refresh
              </Button>
              <Button
                color="error"
                disableElevation
                variant="contained"
                onClick={this.props.clearLogs}
                sx={{ marginLeft: 1 }}
              >
                Clear
              </Button>

              <Divider className="logs-container-divider" sx={{ marginY: 2 }} />

              <code>
                {
                  this.props.logs.map((log, i) => (
                    <p key={i} className={`${log.level}-log`}>
                      {log.timestamp}; {log.text}
                    </p>
                  ))
                }
              </code>
           </CardContent>
					</Card>
  			</Grid>
  		</Grid>
    );
  }
}

export default LogsPage;

import "./App.css";
import Cookies from "js-cookie";
import { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Components
import MainPage from "./components/mainPage.jsx";
import LoginForm from "./components/loginForm.jsx";

const qs = require("query-string");
toast.configure()

const options = {
  position: "bottom-right",
  pauseOnHover: true,
  closeOnClick: true,
  autoClose: 2000,
  theme: "dark"
}

const theme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5"
    },
    primary: {
      main: "#039BE5"
    },
    secondary: {
      main: "#14213d"
    }
  },
  typography: {
    fontFamily: [
      '"Montserrat"',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(',')
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    console.log(process.env.NODE_ENV);
    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";

    console.log(this.URL);

    this.state = {
      loggedIn: localStorage.getItem("access-token") ? true : false,
      username: "",
      algoID: "BNF_NE",
      algoStatus: {
        algoStarted: false,
        legs: {}
      },
      orders: [],
      consolidatedOrders: [],
      logs: [],
      settings: {},
      notifications: [],
      unreadNotificationsCount: 0
    }
  }

  request = async (route, method, body, logoutOnElse=true, msgOnSuccess=true) => {
    let res;

    if (method === "POST") {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `access-token ${localStorage.getItem("access-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        },
        body: JSON.stringify(body)
      });
    } else {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `access-token ${localStorage.getItem("access-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        }
      });
    }

    const json = await res.json();
    console.log(json);

    if (json.type === "success") {
      if (msgOnSuccess) {
        toast.success(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "info") {
      if (msgOnSuccess) {
        toast.info(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "error") {
      toast.error(`Error: ${json.message}`, options);

      return json

    } else if (json.type === "generateToken") {
      console.log(`Redirecting to ${json.url}`);
      window.location = json.url;

      return json

    } else {
      toast.error(`${json.type}: ${json.message}`, options);
      if (logoutOnElse) {
        toast.error("Logged out !", options);
        this.handleLogout();
      }

      return json
    }
  }

  componentDidMount = async () => {
    let localStorageState = localStorage.getItem("iron-fly-algo-page");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      localStorage.setItem("iron-fly-algo-page", this.state.algoID);
    } else {
      this.setState({
        algoID: localStorageState
      });
    }

    if (this.state.loggedIn) {
      const json = await this.request("current-user/", "GET");

      if (json.type === "success") {
        this.fetchBrokerAccessToken();
      }
    }
  }

  getCsrfCookie = () => {
    return Cookies.get("csrftoken");
  }

  handleLogin = async state => {
    const json = await this.request("login/", "POST", state, false);

    if (json.type === "success") {
      localStorage.setItem("access-token", json.accessToken);

      this.setState({
        loggedIn: true
      });

      this.fetchBrokerAccessToken();
    }
  }

  handleLogout = () => {
    localStorage.removeItem("access-token");
    this.setState({loggedIn: false});
  }

  fetchBrokerAccessToken = async () => {
    let parsed = qs.parse(window.location.search);
    let reqToken = parsed.request_token;

    console.log(reqToken);

    if (reqToken) {
      const json = await this.request("set-access-token/", "POST", {
        request_token: reqToken
      }, true);

      if (json.type === "success") {
        window.location.search = "";
      } else {
        return
      }
    }

    if (this.state.loggedIn) {
      const json = await this.request("broker-token/", "GET", null, true);

      if (json.type === "success") {
        this.setState({
          username: json.username
        });
      }
    }
  }

  setAlgoID = algoID => {
    this.setState({
      algoID: algoID
    });

    localStorage.setItem("iron-fly-algo-page", algoID);
  }

  fetchAlgoStatus = async () => {
    const json = await this.request(`algo/status/${this.state.algoID}/`, "GET", null, true, false);

    if (json.type === "success") {
      this.setState({
        algoStatus: json.algoStatus
      });
    }
  }

  startAlgo = async state => {
    await this.request(`algo/start/${this.state.algoID}/`, "POST", state, true);
    this.fetchAlgoStatus();
  }

  stopAlgo = async state => {
    await this.request(`algo/stop/${this.state.algoID}/`, "PUT", state, true);
    this.fetchAlgoStatus();
  }

  squareoffAlgo = async state => {
    await this.request(`algo/squareoff/${this.state.algoID}/`, "PUT", state, true);
    this.fetchAlgoStatus();
  }

  fetchOrders = async () => {
    const json = await this.request(`orders/${this.state.algoID}/`, "GET");

    if (json.type === "success") {
      this.setState({
        orders: json.orders
      });
    }
  }

  clearOrders = async () => {
    await this.request(`orders/clear/${this.state.algoID}/`, "DELETE");
    this.fetchOrders();
  }

  fetchConsolidatedOrders = async () => {
    const json = await this.request(`orders/consolidated/${this.state.algoID}/`, "GET");

    if (json.type === "success") {
      this.setState({
        consolidatedOrders: json.orders
      });
    }
  }

  clearConsolidatedOrders = async () => {
    await this.request(`orders/consolidated/clear/${this.state.algoID}/`, "DELETE");
    this.fetchConsolidatedOrders();
  }

  fetchLogs = async () => {
    const json = await this.request(`logs/${this.state.algoID}/`, "GET");

    if (json.type === "success") {
      this.setState({
        logs: json.logs
      });
    }
  }

  clearLogs = async () => {
    await this.request(`logs/clear/${this.state.algoID}/`, "DELETE");
    await this.fetchLogs();
  }

  startStrategy = async () => {
    const json = await this.request(`strategy/start/${this.state.algoID}/`, "PUT");

    if (json.type === "success") {
      this.fetchSettings();
    }
  }

  stopStrategy = async () => {
    const json = await this.request(`strategy/stop/${this.state.algoID}/`, "PUT");

    if (json.type === "success") {
      this.fetchSettings();
    }
  }

  fetchSettings = async () => {
    const json = await this.request(`settings/${this.state.algoID}/`, "GET", null);

    if (json.type === "success") {
      this.setState({
        settings: json.settings
      });
    }
  }

  fetchNotifications = async () => {
    const json = await this.request(`notifications/${this.state.algoID}/`, "GET", null, true, false);

    if (json.type === "success") {
      this.setState({
        notifications: json.notifications,
        unreadNotificationsCount: json.unreadNotificationsCount
      });
    }
  }

  readAllNotifications = async () => {
    await this.request(`notifications/read/${this.state.algoID}/`, "PUT", null, true, false);
    // this.fetchNotifications();
  }

  render() {
    let page = null;

    if (!this.state.loggedIn) {
      page = <LoginForm
        handleLogin={this.handleLogin}
      />
    } else {
      page = <MainPage
        algoID={this.state.algoID}
        setAlgoID={this.setAlgoID}
        username={this.state.username}
        handleLogout={this.handleLogout}

        startAlgo={this.startAlgo}
        stopAlgo={this.stopAlgo}
        squareoffAlgo={this.squareoffAlgo}
        algoStatus={this.state.algoStatus}
        fetchAlgoStatus={this.fetchAlgoStatus}

        orders={this.state.orders}
        fetchOrders={this.fetchOrders}
        clearOrders={this.clearOrders}
        consolidatedOrders={this.state.consolidatedOrders}
        fetchConsolidatedOrders={this.fetchConsolidatedOrders}
        clearConsolidatedOrders={this.clearConsolidatedOrders}

        logs={this.state.logs}
        fetchLogs={this.fetchLogs}
        clearLogs={this.clearLogs}

        settings={this.state.settings}
        stopStrategy={this.stopStrategy}
        startStrategy={this.startStrategy}
        fetchSettings={this.fetchSettings}

        notifications={this.state.notifications}
        unreadNotificationsCount={this.state.unreadNotificationsCount}
        fetchNotifications={this.fetchNotifications}
        readAllNotifications={this.readAllNotifications}
      />
    }

    return (
      <ThemeProvider id="app" theme={theme}>
        <CssBaseline />
        {page}
      </ThemeProvider>
    );
  }
}

export default App;
import { Component, Fragment } from "react";

import {
  Card,
  Grid,
  Table,
  Dialog,
  Button,
  Divider,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  DialogTitle,
  CardContent,
  DialogContent,
  TableContainer,
  DialogContentText,
} from "@mui/material";

class AlgoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      algoUpdateIntervalID: null,
      showStopConfirmationModal: false,
      showSquareoffConfirmationModal: false
    }
  }

  componentDidMount() {
    var intervalId = setInterval(this.props.fetchAlgoStatus, 1200);
    this.setState({
      algoUpdateIntervalID: intervalId
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.algoUpdateIntervalID);
  }

  handleStopAlgo = e => {
    this.closeStopConfirmationModal();
    this.props.stopAlgo();
  }

  handleSquareoffAlgo = e => {
    this.closeSquareoffConfirmationModal();
    this.props.squareoffAlgo();
  }

  closeStopConfirmationModal = e => {
    this.setState({
      showStopConfirmationModal: false
    });
  }

  closeSquareoffConfirmationModal = e => {
    this.setState({
      showSquareoffConfirmationModal: false
    });
  }

  askStopConfirmation = e => {
    this.setState({
      showStopConfirmationModal: true
    });
  }

  askSquareoffConfirmation = e => {
    this.setState({
      showSquareoffConfirmationModal: true
    });
  }

  getClassName = status => {
    if (status === "active" || status == "opened") {
      return "color-green"
    } else if (status === "exited") {
      return "color-red"
    } else if (status === "inactive") {
      return "color-cyan"
    }
  }

  render() {
    const algoType = this.props.getAlgoType();

    return (
      <div>
    		<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
    			<Grid item md={10} xs={12}>
  					<Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
              <CardContent>
                <Typography variant="h5">{this.props.formatAlgoID(this.props.algoID)} Algo Statistics</Typography>
                <Divider sx={{ marginY: 2 }} />

                {
                  algoType === "NE" ?
                  <Fragment>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Leg No.</TableCell>
                            <TableCell align="center">Instrument</TableCell>
                            <TableCell align="center">Trade</TableCell>
                            <TableCell align="center">Trailed SL</TableCell>
                            <TableCell align="center">Entry Price</TableCell>
                            <TableCell align="center">LTP</TableCell>
                            <TableCell align="center">P&L</TableCell>
                            <TableCell align="center">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            Object.entries(this.props.algoStatus.legs).map(([legNo, leg], i) => (
                              <TableRow
                                key={i}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              >
                                <TableCell align="center" component="th" scope="row">{leg.legId}</TableCell>
                                <TableCell align="center">{leg.displayIns}</TableCell>
                                <TableCell align="center">{leg.trade}</TableCell>
                                <TableCell align="center">{leg.trailedSL}</TableCell>
                                <TableCell align="center">{leg.entryPrice}</TableCell>
                                <TableCell align="center">{leg.ltp}</TableCell>
                                <TableCell align="center">{leg.pnl}</TableCell>
                                <TableCell align="center" className={this.getClassName(leg.status)}><strong>{leg.status.toUpperCase()}</strong></TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Expiry</TableCell>
                            <TableCell align="center">Trading Mode</TableCell>
                            <TableCell align="center">Lots</TableCell>
                            <TableCell align="center">Max Loss</TableCell>
                            <TableCell align="center">Total P&L</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell align="center">{this.props.algoStatus.expiry}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.tradingMode}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.lots}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.maxLoss}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.totalPnl}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Fragment> : null
                }

                {
                  algoType === "E" ?
                  <Fragment>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Leg No.</TableCell>
                            <TableCell align="center">Instrument</TableCell>
                            <TableCell align="center">Trade</TableCell>
                            <TableCell align="center">Entry Price</TableCell>
                            <TableCell align="center">LTP</TableCell>
                            <TableCell align="center">P&L</TableCell>
                            <TableCell align="center">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            Object.entries(this.props.algoStatus.legs).map(([legNo, leg], i) => (
                              <TableRow
                                key={i}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              >
                                <TableCell align="center" component="th" scope="row">{leg.legId}</TableCell>
                                <TableCell align="center">{leg.displayIns}</TableCell>
                                <TableCell align="center">{leg.trade}</TableCell>
                                <TableCell align="center">{leg.entryPrice}</TableCell>
                                <TableCell align="center">{leg.ltp}</TableCell>
                                <TableCell align="center">{leg.pnl}</TableCell>
                                <TableCell align="center" className={this.getClassName(leg.status)}><strong>{leg.status.toUpperCase()}</strong></TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Capital Size</TableCell>
                            <TableCell align="center">Max Risk %</TableCell>
                            <TableCell align="center">Margin Req.</TableCell>
                            <TableCell align="center">Lots</TableCell>
                            <TableCell align="center">Max Loss</TableCell>
                            <TableCell align="center">Total P&L</TableCell>
                            <TableCell align="center">Trading Mode</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell align="center">{this.props.algoStatus.capitalSize}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.maxRiskPct}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.marginReqd}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.lots}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.maxLoss}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.totalPnl}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.tradingMode}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">ATM Strike</TableCell>
                            <TableCell align="center">CE Start Price</TableCell>
                            <TableCell align="center">PE Start Price</TableCell>
                            <TableCell align="center">Implied Futures Value</TableCell>
                            <TableCell align="center">Implied Futures Time</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell align="center">{this.props.algoStatus.atmStrike}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.ceStartPrice}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.peStartPrice}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.impliedFuturesValue}</TableCell>
                            <TableCell align="center">{this.props.algoStatus.impliedFuturesTime}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Fragment> : null
                }

                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2, marginX: 1 }}
                  onClick={this.askSquareoffConfirmation}
                >
                  Squareoff Algo
                </Button>

                <Button
                  color="error"
                  disableElevation
                  variant="contained"
                  sx={{ marginTop: 2, marginX: 1 }}
                  onClick={this.askStopConfirmation}
                >
                  Stop Algo
                </Button>
              </CardContent>
  					</Card>
    			</Grid>
    		</Grid>

        <Dialog onClose={this.closeStopConfirmationModal} open={this.state.showStopConfirmationModal}>
          <DialogTitle>Are you Sure ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to stop the algo ?
            </DialogContentText>

            <Button
              disableElevation
              color="secondary"
              variant="contained"
              sx={{ marginTop: 2, marginRight: 1 }}
              onClick={this.closeStopConfirmationModal}
            >
              Go back
            </Button>

            <Button
              color="error"
              disableElevation
              variant="contained"
              sx={{ marginTop: 2 }}
              onClick={this.handleStopAlgo}
            >
              Stop algo
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog onClose={this.closeSquareoffConfirmationModal} open={this.state.showSquareoffConfirmationModal}>
          <DialogTitle>Are you Sure ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to squareoff the algo ?
            </DialogContentText>

            <Button
              disableElevation
              color="secondary"
              variant="contained"
              sx={{ marginTop: 2, marginRight: 1 }}
              onClick={this.closeSquareoffConfirmationModal}
            >
              Go back
            </Button>

            <Button
              color="primary"
              disableElevation
              variant="contained"
              sx={{ marginTop: 2 }}
              onClick={this.handleSquareoffAlgo}
            >
              Squareoff algo
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AlgoPage;
import { Component } from "react";

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider
} from "@mui/material";

class NotificationsPage extends Component {
  componentWillUnmount() {
    this.props.readAllNotifications();
  }

  render() {
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
        <Grid item md={10} xs={12}>
          <Card sx={{ textAlign: "center", marginBottom: 2 }} variant="outlined">
            <CardContent>
              <Typography variant="h5">Notifications</Typography>
              <Divider sx={{ marginY: 2 }} />

              {
                this.props.notifications.map((notification, i) => (
                  <div
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <p className={notification.read ? "" : "color-cyan"}>{notification.messageTime}: {notification.messageText}</p>
                  </div>
                ))
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default NotificationsPage;

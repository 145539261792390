import { Component } from "react";

import {
  Box,
  Grid,
  Link,
  Paper,
  Button,
  Avatar,
  Typography,
  TextField,
  CssBaseline
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleInputChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    })
  }

  handleFormSubmit = e => {
    e.preventDefault();
    this.props.handleLogin(this.state);
  }

  render() {
    return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url('static/login form.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              mt: 18,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Welcome back, please login !
            </Typography>
            <Box component="form" onSubmit={this.handleFormSubmit}>
              <TextField
                required
                fullWidth
                type="text"
                name="username"
                label="Username"
                sx={{ marginTop: 2 }}
                value={this.state.username}
                onChange={this.handleInputChange}
              />

              <TextField
                required
                fullWidth
                type="password"
                name="password"
                label="Password"
                sx={{ marginTop: 2 }}
                value={this.state.password}
                onChange={this.handleInputChange}
              />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ marginTop: 2, width: "100%" }}
              >
                Login
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

//   		<Grid container>
//   			<Grid item xs={6}>
// 					<div className="login-form-left">
//             <Typography variant="h5">Welcome Back, Please Login !</Typography>
//             <Divider sx={{ marginY: 2 }} />
//             <form onSubmit={this.handleFormSubmit}>
//               <TextField
//                 required
//                 fullWidth
//                 type="text"
//                 name="username"
//                 label="Username"
//                 variant="filled"
//                 sx={{ marginTop: 1 }}
//                 value={this.state.username}
//                 onChange={this.handleInputChange}
//               />

//               <TextField
//                 required
//                 fullWidth
//                 type="password"
//                 name="password"
//                 label="Password"
//                 variant="filled"
//                 sx={{ marginTop: 1 }}
//                 value={this.state.password}
//                 onChange={this.handleInputChange}
//               />

//               <Button
//                 type="submit"
//                 color="success"
//                 disableElevation
//                 variant="contained"
//                 sx={{ marginTop: 2 }}
//               >
//                 Login
//               </Button>
//             </form>
//           </div>
//   			</Grid>
//   			<Grid item xs={6}>
// 					<div className="login-form-right">
//           </div>
//   			</Grid>
//   		</Grid>
//     );
//   }
// }

export default LoginForm;
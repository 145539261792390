import { Component } from "react";
import { Badge, Divider, Drawer, List, ListItem, ListItemText, ListItemButton } from "@mui/material";

class Sidebar extends Component {
  render() {
    return (
      <Drawer keepMounted variant={this.props.drawerType} anchor="left" open={this.props.open} onClose={this.props.onClose}
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          }
        }}
      >
        <List>
          <ListItem sx={{ pr: 0, pl: 2.4 }}>
            <img src="static/Khishor-capital.png" alt="Khishor Capital" />
          </ListItem>

          <Divider />

          <ListItemButton
            selected={this.props.algoID === "BNF_NE"}
            onClick={() => this.props.setAlgoID("BNF_NE")}
          >
            <ListItemText
              primary="Banknifty NE"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.algoID === "N50_MCS_E"}
            onClick={() => this.props.setAlgoID("N50_MCS_E")}
          >
            <ListItemText
              primary="Nifty Mid-Cap Select E"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.algoID === "FNF_E"}
            onClick={() => this.props.setAlgoID("FNF_E")}
          >
            <ListItemText
              primary="Finnifty E"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.algoID === "BNF_E"}
            onClick={() => this.props.setAlgoID("BNF_E")}
          >
            <ListItemText
              primary="Banknifty E"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.algoID === "N50_E"}
            onClick={() => this.props.setAlgoID("N50_E")}
          >
            <ListItemText
              primary="Nifty 50 E"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.algoID === "S_E"}
            onClick={() => this.props.setAlgoID("S_E")}
          >
            <ListItemText
              primary="Sensex E"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>

          <Divider />

          <ListItemButton
            selected={this.props.currentPage === "algo"}
            onClick={() => this.props.changeCurrentPage("algo")}
          >
            <ListItemText
              primary="Algo"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.currentPage === "orderbook"}
            onClick={() => this.props.changeCurrentPage("orderbook")}
          >
            <ListItemText
              primary="Orderbook"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.currentPage === "logs"}
            onClick={() => this.props.changeCurrentPage("logs")}
          >
            <ListItemText
              primary="Logs"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.currentPage === "settings"}
            onClick={() => this.props.changeCurrentPage("settings")}
          >
            <ListItemText
              primary="Settings"
              sx={{ textAlign: "center" }}
            />
          </ListItemButton>
          <ListItemButton
            selected={this.props.currentPage === "notifications"}
            onClick={() => this.props.changeCurrentPage("notifications")}
          >
            <ListItemText
              primary="Notifications"
              sx={{ textAlign: "center" }}
            />
            <Badge badgeContent={this.props.unreadNotificationsCount} color="error">
            </Badge>
          </ListItemButton>
        </List>
      </Drawer>
    );
  }
}

export default Sidebar;
import { Component } from "react";
import MenuIcon from "@mui/icons-material/Menu";

import {
  Box,
  Badge,
  Button,
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Typography
} from "@mui/material";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorElNav: null,
      anchorElUser: null
    }
  }

  handleOpenNavMenu = event => {
    this.setState({
      anchorElNav: event.currentTarget
    });
  }

  handleCloseNavMenu = () => {
    this.setState({
      anchorElNav: null
    });
  }

  render() {
    return (
      <AppBar position="sticky" elevation={2} color="secondary">
        <Container maxWidth="xl">
          <Toolbar disableGutters color="dark">
            <IconButton size="large" onClick={this.props.toggleSidebar} color="inherit">
              <Badge badgeContent={this.props.unreadNotificationsCount} color="error">
                <MenuIcon />
              </Badge>
            </IconButton>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              {/* <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(this.state.anchorElNav)}
                onClose={this.handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem key="Algo" onClick={() => this.goto("algo")}>
                  <Typography textAlign="center">Algo</Typography>
                </MenuItem>
                <MenuItem key="Orderbook" onClick={() => this.goto("orderbook")}>
                  <Typography textAlign="center">Orderbook</Typography>
                </MenuItem>
                <MenuItem key="Logs" onClick={() => this.goto("logs")}>
                  <Typography textAlign="center">Logs</Typography>
                </MenuItem>
                <MenuItem key="Settings" onClick={() => this.goto("settings")}>
                  <Typography textAlign="center">Settings</Typography>
                </MenuItem>
                <MenuItem key="Notifications" onClick={() => this.goto("notifications")}>
                  <Typography textAlign="center">Notifications</Typography>
                </MenuItem>
              </Menu> */}
            </Box>

            <Typography
              noWrap
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "flex" }}
            >
              Hi, {this.props.username}
            </Typography>

            {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                key="Algo"
                onClick={() => this.goto("algo")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Algo
              </Button>
              <Button
                key="Orderbook"
                onClick={() => this.goto("orderbook")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Orderbook
              </Button>
              <Button
                key="Logs"
                onClick={() => this.goto("logs")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Logs
              </Button>
              <Button
                key="Settings"
                onClick={() => this.goto("settings")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Settings
              </Button>
              <Button
                key="Notifications"
                onClick={() => this.goto("notifications")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Badge badgeContent={this.props.unreadNotificationsCount} color="error">
                  <MailIcon color="white" />
                </Badge>
              </Button>
            </Box> */}

            <Box sx={{ flexGrow: 0 }}>
              <Button variant="contained" disableElevation color="error" onClick={this.props.handleLogout}>Logout</Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default Navbar;
